import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles/bioModal.scss";

const BioModal = ({ BioTitle, BioThumb, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Col xs={12} lg={6} className="p-4 p-xl-5">
      <div className="faculty-bio">
        <Row className="align-items-center mb-n5">
          <Col xs={4} className="ms-4">
            <img
              src={BioThumb}
              className="img-fluid img-rounded"
              alt={`${BioTitle} thumbnail`}
            />
          </Col>
          <Col xs={7}>
            <h4 className="meetTheSpeakers">Meet the Speakers</h4>
            <h2 className="speakerName">{BioTitle}</h2>
          </Col>
        </Row>
        <Row>
          <Col className="bio-box">
            <Collapse in={isOpen}>
              <div>{children}</div>
            </Collapse>
            <Button
              onClick={() => setIsOpen(!isOpen)}
              aria-controls={BioTitle}
              aria-expanded={isOpen}
              className="mt-3"
              variant="link"
            >
              {isOpen ? (
                <>
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="st0"
                      d="M6.8,5.7H11c0.5,0,0.8,0.4,0.8,0.8S11.5,7.3,11,7.3H6.9h0c-0.5,0-0.4,0-0.8,0s-0.4,0-0.8,0l0,0H1
            C0.5,7.3,0.2,7,0.2,6.5S0.5,5.7,1,5.7h4.2"
                      fill="#323337"
                    />
                    <path className="st0" d="M6,5.6" fill="#323337" />
                  </svg>{" "}
                  Read Less
                </>
              ) : (
                <>
                  <svg
                    width="12"
                    height="13"
                    viewBox="0 0 12 13"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 7.33268H6.83335V11.4993C6.83335 11.9577 6.45835 12.3327 6.00002 12.3327C5.54169 12.3327 5.16669 11.9577 5.16669 11.4993V7.33268H1.00002C0.541687 7.33268 0.166687 6.95768 0.166687 6.49935C0.166687 6.04102 0.541687 5.66602 1.00002 5.66602H5.16669V1.49935C5.16669 1.04102 5.54169 0.666016 6.00002 0.666016C6.45835 0.666016 6.83335 1.04102 6.83335 1.49935V5.66602H11C11.4584 5.66602 11.8334 6.04102 11.8334 6.49935C11.8334 6.95768 11.4584 7.33268 11 7.33268Z"
                      fill="#323337"
                    />
                  </svg>{" "}
                  Read More{" "}
                </>
              )}
            </Button>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default BioModal;
